import React, { useEffect, useState } from "react";
// import Sidebar from "../../../../component/admin/defaulLayout/Sidebar";
import Index from "../../../Index";
// import SalseCharts from "./DashboardCharts/SalseCharts";
import TreeMap from "./DashboardCharts/TreeMap";
import moment from "moment";
import { createSearchParams } from "react-router-dom";
import {
  getCategoryDetail,
  getSalsePartyWises,
  getInvoiceDetailDashboard,
  getTotalSalseDashboard,
  getTotalPurchaseDashboard,
  getTotalHdfcSales,
  getTotalKotakSales,
  getInvoiceTotalDetailByOverDue,
  getDirectIndirectExpenseTotal,
  getTripDashboardList,
  getInvoiceTotalDetailByOverDuev2,
  getAllMaintenanceList,
  getAllBreakdowns,
  getDashboardBankAmountDetails,
} from "../../../../redux/admin/action";
import NewSalseChart from "./DashboardCharts/NewSalseChart";
import { getFractionValue } from "../../../../config/CommonFunctions";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import OrderView from "./DashboardCharts/OrderView";
import CategoryTreeMap from "./DashboardCharts/CategoryWiseTreeMap";
import SalesTreeMap from "./DashboardCharts/SalesTreeMap";
import BreakDwonDetails from "./DashboardCharts/BreakDownDetails";
import DocumentDetails from "./DashboardCharts/DocumentDetails";
import KotakBankDataDetails from "./DashboardCharts/KotakBankDataDetails";
import HDFCBankDataDetails from "./DashboardCharts/HDFCBankDataDetails";
import ElectricalDetails from "./DashboardCharts/ElectricalDetail";
import MechanicalDetails from "./DashboardCharts/MechanicalDetails";
import FabricationDetails from "./DashboardCharts/FabricationDetails";
import ProcurementDetails from "./DashboardCharts/ProcurementDetails";
import AllDashboardBankData from "./AllDashboardBankData";
import DashboardBankTransactions from "./DashboardBankTransactions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CompanyDash = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const {
    totalSalseDashboard,
    totalPurchaseDashboard,
    invoiceDetailsDashboard,
    categoryWiseDashboard,
    salsePartywise,
    totalHdfcSales,
    totalKotakSales,
    expenseTotal,
    tripDashboardList,
    dashboardBankAmounts,
    adminDetails,
    activeCompany,
  } = Index.useSelector((state) => state.AdminReducer);

  const { webPermission } = adminDetails?.permission;
  const params = Index.useParams();

  const financialYear =
    new Date().getFullYear() + "-" + (new Date().getFullYear() + 1);
  const currentMonthName = new Date().toLocaleString("default", {
    month: "long",
  });

  const currentYearInNumber = new Date().getFullYear();

  const [month, setMonth] = useState("Sales");
  const [bargeWise, setBargeWise] = useState("all");
  const [invoice, setInvoice] = useState("0-15");
  const [totalInvoice, setTotalInvoice] = useState([]);
  const [categoryWise, setCategoryWise] = useState("all");
  const [partyWise, setPartyWise] = useState("all");
  const [orderViewFilterOption, setOrderViewFilterOption] = useState("today");
  const [breakdownFilterOption, setBreakdownFilterOption] = useState("Craft");
  const [financialYearOptions, setFinancialYearOptions] = useState([]);
  const [currentFinancialYear, setCurrentFinalcialYear] = useState("");
  const [currentFinancialYearForCategory, setCurrentFinalcialYearForCategory] =
    useState("");
  const [currentFinancialYearForSales, setCurrentFinancialYearForSales] =
    useState("");
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [breakDownList, setBreakDownList] = useState([]);
  const [loadingDueInvoice, setLoadingDueInvoice] = useState(false);

  const handleBindBargeWiseFinalncialYearOptions = () => {
    let options = [];
    let today = new Date();
    let currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    if (currentMonth >= 3) {
      setCurrentFinalcialYear(`${currentYear}-${currentYear + 1}`);
      setCurrentFinalcialYearForCategory(`${currentYear}-${currentYear + 1}`);
      setCurrentFinancialYearForSales(`${currentYear}-${currentYear + 1}`);
    } else {
      setCurrentFinalcialYear(`${currentYear - 1}-${currentYear}`);
      setCurrentFinalcialYearForCategory(`${currentYear - 1}-${currentYear}`);
      setCurrentFinancialYearForSales(`${currentYear - 1}-${currentYear}`);
    }

    const lastIndex = currentYear - 2023;

    for (let i = 0; i <= lastIndex; i++) {
      let startYear = currentYear - i;
      let endYear = startYear + 1;

      let financialYearText = `${startYear}-${endYear}`;
      let financialYearValue = `${startYear}-${endYear}`;

      options.push({ label: financialYearText, value: financialYearValue });
    }
    setFinancialYearOptions(options);
  };

  // when the page is first time load then used...
  React.useEffect(() => {
    handleBindBargeWiseFinalncialYearOptions();
  }, []);

  const monthandleChange = (event) => {
    setMonth(event.target.value);
  };
  const categoryHandleChange = (event) => {
    setCategoryWise(event.target.value);
  };
  const partyWiseHandleChange = (event) => {
    setPartyWise(event.target.value);
  };

  const breakDownCraft = () => {
    try {
      dispatch(getAllMaintenanceList()).then((res) => {
        if (res?.status === 200) {
          setMaintenanceList(res?.data);
        } else {
          setMaintenanceList([]);
        }
      });
    } catch (error) {}
  };
  const BreakDownTanker = () => {
    try {
      dispatch(getAllBreakdowns()).then((res) => {
        if (res?.status == 200) {
          setBreakDownList(res?.data);
        } else {
          setBreakDownList([]);
        }
      });
    } catch (error) {}
  };
  useEffect(() => {
    breakDownCraft();
    BreakDownTanker();
  }, []);

  let finalBreakDown = [...maintenanceList];
  breakDownList.map((item) => {
    if (!item.revokedBy && !item.revokedDate) {
      finalBreakDown.push(item);
    }
  });

  useEffect(() => {
    dispatch(getTotalSalseDashboard(activeCompany?.tallyCompanyName));
    dispatch(getTotalPurchaseDashboard());
    dispatch(getTotalHdfcSales());
    dispatch(getTotalKotakSales());
    dispatch(getDirectIndirectExpenseTotal(activeCompany?.tallyCompanyName));
    dispatch(getTripDashboardList());
    dispatch(getDashboardBankAmountDetails());
    // getTotalProfit();
  }, [dispatch, activeCompany]);

  const [purchaseAmount, setPurchaseAmount] = useState(0);
  useEffect(() => {
    if (totalPurchaseDashboard) {
      setPurchaseAmount(parseFloat(totalPurchaseDashboard));
    }
  }, [totalPurchaseDashboard]);
  // when the get due invoice dashboard side then used...
  const handleGetDueInvoiceData = () => {
    setLoadingDueInvoice(true);
    dispatch(getInvoiceTotalDetailByOverDuev2(activeCompany?.tallyCompanyName))
      .then((res) => {
        if (res.status === 200) {
          setTotalInvoice(res?.data);
          setLoadingDueInvoice(false);
        } else {
          setTotalInvoice([]);
          setLoadingDueInvoice(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setTotalInvoice([]);
        setLoadingDueInvoice(false);
      });
  };

  // get only total invoice api
  useEffect(() => {
    // dispatch(
    //   getInvoiceTotalDetailByOverDuev2(activeCompany?.tallyCompanyName)
    // ).then((res) => {
    //   setTotalInvoice(res?.data);
    // });
    handleGetDueInvoiceData();
  }, [activeCompany]);

  useEffect(() => {
    dispatch(getCategoryDetail(categoryWise));
  }, [categoryWise]);

  useEffect(() => {
    dispatch(getSalsePartyWises(partyWise));
  }, [partyWise]);

  const [grossProfitPercent, setGrossProfitPercent] = useState(0);
  const [netProfitPercent, setNetProfitPercent] = useState(0);

  useEffect(() => {
    if (totalSalseDashboard && expenseTotal?.directExpenseTotal) {
      setGrossProfitPercent(
        (
          ((totalSalseDashboard - expenseTotal?.directExpenseTotal) /
            totalSalseDashboard) *
          100
        ).toFixed(2)
      );
    }
    if (totalSalseDashboard && expenseTotal?.indirectExpenseTotal) {
      setNetProfitPercent(
        (
          ((totalSalseDashboard -
            expenseTotal?.directExpenseTotal -
            expenseTotal?.indirectExpenseTotal) /
            totalSalseDashboard) *
          100
        ).toFixed(2)
      );
    }
  }, [totalSalseDashboard, expenseTotal]);

  const handleInvoiceDetails = (data, dueType, startDate, endDate) => {
    if (dueType === "GRATER_90_DAYS") {
      navigate({
        pathname: "/admin/invoice/due-invoice-details",
        search: createSearchParams({
          to: endDate,
          from: startDate,
          dueType,
          companyId: activeCompany?._id,
          companyName: activeCompany?.tallyCompanyName,
        }).toString(),
      });
    } else {
      navigate({
        pathname: "/admin/invoice/due-invoice-details",
        search: createSearchParams({
          to: endDate,
          from: startDate,
          dueType,
          companyId: activeCompany?._id,
          companyName: activeCompany?.tallyCompanyName,
        }).toString(),
      });
    }
  };

  // HTML Tooltip

  const HtmlTooltip = Index.styled(({ className, ...props }) => (
    <Index.Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${Index.tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function calculatePercentage(numb1, numb2) {
    const percentage = (numb1 / numb2) * 100;
    return Math.round(percentage);
  }

  const orderView = (id) => {
    navigate(`/admin/order/view-order/${id}`);
  };

  // when the user go to category wise invoice details page then used...
  // const handleCategoryWiseInvoiceDetails = React.useCallback(
  //   (data) => {
  //     navigate({
  //       pathname: `/admin/category-wise-invoice-details`,
  //       search: createSearchParams({
  //         month: categoryWise,
  //         categoryId: data?.categoryId,
  //         categoryName: data?._id,
  //       }).toString(),
  //     });
  //   },
  //   [categoryWise]
  // );
  return (
    <>
      <Index.DynamicTitle title="Dashboard" />
      <div className="main-dashboard">
        <Index.Box className="dashboard-boxes">
          {webPermission?.dashboardstats ? (
            <>
              {/* <Index.Box className="d-flex">
                <Index.Typography className="head-title mr-20" component="h1">
                  Dashboard
                </Index.Typography>
              </Index.Box> */}

              <Index.Box className=" heading-line-wrapper">
                <Index.Box className="heading-line">
                  <Index.Typography className="head-title mr-20" component="h1">
                    Dashboard
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

              <Index.Box className="mini-card-main card-wrapper">
                <Index.Box className="mini-card">
                  <Index.Box className="mini-card-img">
                    <img src={Index.Png.card1} alt="" />
                  </Index.Box>
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total Sales{" "}
                      <span className="dash-fin-year"> ({financialYear})</span>
                    </Index.Typography>

                    <Index.Typography className="card-price">
                      ₹{" "}
                      {totalSalseDashboard
                        ? parseFloat(
                            totalSalseDashboard < 0
                              ? totalSalseDashboard * -1
                              : totalSalseDashboard
                          )?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card">
                  <Index.Box className="mini-card-img mini-card-2">
                    <img src={Index.Png.card2} alt="" />
                  </Index.Box>
                  <Index.Box className="card-right-content">
                    <Index.Typography
                      className="card-total card-info"
                      component="p"
                    >
                      Total Purchase{" "}
                      <span className="dash-fin-year"> ({financialYear})</span>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Index.Typography>
                              Direct Expense :{" "}
                              {getFractionValue(
                                expenseTotal?.directExpenseTotal
                              )}
                            </Index.Typography>
                            <Index.Typography>
                              Indirect Expense :{" "}
                              {getFractionValue(
                                expenseTotal?.indirectExpenseTotal
                              )}
                            </Index.Typography>
                          </React.Fragment>
                        }
                      >
                        <Index.InfoIcon className="info-icon" />
                      </HtmlTooltip>
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      {getFractionValue(
                        expenseTotal?.directExpenseTotal +
                          expenseTotal?.indirectExpenseTotal
                      )}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="mini-card">
                  <Index.Box className="mini-card-img mini-card-3">
                    <img src={Index.Png.card3} alt="" />
                  </Index.Box>
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Gross Profit{" "}
                      <span className="dash-fin-year"> ({financialYear})</span>
                      <span className="net-per">
                        {" "}
                        ({`${grossProfitPercent} %`})
                      </span>
                    </Index.Typography>
                    <Index.Typography
                      className="card-total min-26"
                      component="p"
                    >
                      Net Profit{" "}
                      <span className="dash-fin-year"> ({financialYear})</span>
                      <span className="net-per">
                        {" "}
                        ({`${netProfitPercent} %`})
                      </span>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="mini-card">
                  <Index.Box className="mini-card-img mini-card-4">
                    <img src={Index.Png.card4} alt="" />
                  </Index.Box>
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total Kotak Balance
                      <span className="dash-fin-year"> ({financialYear})</span>
                    </Index.Typography>
                    {dashboardBankAmounts?.length &&
                      dashboardBankAmounts?.find(
                        (res) => res.name === "kotak"
                      ) ? (
                      dashboardBankAmounts?.find(
                        (res) => res.name === "kotak"
                      ) !== undefined ? (
                        dashboardBankAmounts?.find(
                          (res) => res.name === "kotak"
                        )?.amount > 0 ? (
                          <>
                            <Index.Typography className="card-price  ">
                              (₹{" "}
                              {dashboardBankAmounts
                                ?.find((res) => res.name === "kotak")
                                ?.amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              )
                            </Index.Typography>
                          </>
                        ) : (
                          <>
                            {dashboardBankAmounts?.find(
                              (res) => res.name === "kotak"
                            )?.amount === 0 ? (
                              <>
                                <Index.Typography className="card-price">
                                  {" "}
                                  ₹{" "}
                                  {0?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Index.Typography>
                              </>
                            ) : (
                              <>
                                <Index.Typography className="card-price kotak-balance">
                                  (₹{" "}
                                  {(
                                    dashboardBankAmounts?.find(
                                      (res) => res.name === "kotak"
                                    )?.amount * -1
                                  )?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  )
                                </Index.Typography>
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <Index.Typography className="card-price">
                            {" "}
                            ₹{" "}
                            {0?.toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Index.Typography>
                        </>
                      )
                    ) : (
                      <Index.Typography className="card-price">
                        {" "}
                        ₹{" "}
                        {0?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Index.Typography>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card">
                  <Index.Box className="mini-card-img mini-card-5">
                    <img src={Index.Png.card4} alt="" />
                  </Index.Box>
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total HDFC Balance{" "}
                      <span className="dash-fin-year"> ({financialYear})</span>
                    </Index.Typography>

                    {dashboardBankAmounts?.length &&
                      dashboardBankAmounts?.find((res) => res.name === "hdfc") ? (
                      dashboardBankAmounts?.find(
                        (res) => res.name === "hdfc"
                      ) !== undefined ? (
                        dashboardBankAmounts?.find((res) => res.name === "hdfc")
                          ?.amount > 0 ? (
                          <>
                            <Index.Typography className="card-price  ">
                              (₹{" "}
                              {dashboardBankAmounts
                                ?.find((res) => res.name === "hdfc")
                                ?.amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              )
                            </Index.Typography>
                          </>
                        ) : (
                          <>
                            {dashboardBankAmounts?.find(
                              (res) => res.name === "hdfc"
                            )?.amount === 0 ? (
                              <>
                                <Index.Typography className="card-price">
                                  {" "}
                                  ₹{" "}
                                  {0?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Index.Typography>
                              </>
                            ) : (
                              <>
                                <Index.Typography className="card-price kotak-balance">
                                  (₹{" "}
                                  {(
                                    dashboardBankAmounts?.find(
                                      (res) => res.name === "hdfc"
                                    )?.amount * -1
                                  )?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  )
                                </Index.Typography>
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <Index.Typography className="card-price">
                          {" "}
                          ₹{" "}
                          {0?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Index.Typography>
                      )
                    ) : (
                      <Index.Typography className="card-price">
                        {" "}
                        ₹{" "}
                        {0?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Index.Typography>
                    )}
                  </Index.Box>
                </Index.Box> */}
              </Index.Box>
            </>
          ) : (
            <></>
          )}

          {webPermission?.dashboardMonthlySales ||
          webPermission?.dashboardBargeWise ||
          webPermission?.dashboardDueInvoiceDetails ||
          webPermission?.dashboardBankData ||
          webPermission?.dashboardBankTransactions ||
          webPermission?.dashboardCategoryWise ||
          webPermission?.dashboardTop10SalesPartyWise ? (
            <>
              <Index.Box className=" heading-line-wrapper">
                <Index.Box className="heading-line">
                  <Index.Typography className="head-title mr-20" component="h1">
                    Finanace
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </>
          ) : (
            <></>
          )}

          <Index.Box className="chart-main-wrapp category-sec-main chart-main-wrapp">
            {webPermission?.dashboardMonthlySales ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Monthly Sales
                    </Index.Typography>
                    {/* <Index.Box> */}
                    <Index.FormControl
                      className="main-select-graph"
                      sx={{ minWidth: 80 }}
                    >
                      <Index.InputLabel id="demo-simple-select-helper-label"></Index.InputLabel>
                      <Index.Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={month}
                        onChange={monthandleChange}
                        className="select-graph"
                      >
                        <Index.MenuItem value={"Sales"}>Sales</Index.MenuItem>
                        <Index.MenuItem value={"Purchase"}>
                          Purchase
                        </Index.MenuItem>
                      </Index.Select>
                    </Index.FormControl>
                    {/* </Index.Box> */}
                  </Index.Box>
                  <Index.Box className="chart-graph-devider">
                    {/* <SalseCharts month={month} /> */}
                    <NewSalseChart month={month} />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardBargeWise ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Box>
                      <Index.Typography className="chart-title" component="p">
                        Barge Wise
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box
                      className=""
                      width="50%"
                      display="flex"
                      justifyContent="end"
                      gap="10px"
                    >
                      <Index.FormControl className="main-select-graph m-2">
                        <Index.Select
                          value={currentFinancialYear}
                          // defaultValue={currentFinancialYear}
                          onChange={(e) =>
                            // handlceChangeFinancialYear(e.target.value)
                            setCurrentFinalcialYear(e.target.value)
                          }
                          className="select-graph"
                          id="demo-simple-select"
                          MenuProps={MenuProps}
                        >
                          {financialYearOptions?.map((el) => (
                            <Index.MenuItem value={el.value}>
                              {el.label}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormControl className="main-select-graph m-2">
                        <Index.Select
                          value={bargeWise}
                          onChange={(e) => {
                            setBargeWise(e.target.value);
                          }}
                          className="select-graph"
                          id="demo-simple-select"
                        >
                          <Index.MenuItem value="all">All</Index.MenuItem>
                          <Index.MenuItem value="04">April</Index.MenuItem>
                          <Index.MenuItem value="05">May</Index.MenuItem>
                          <Index.MenuItem value="06">June</Index.MenuItem>
                          <Index.MenuItem value="07">July</Index.MenuItem>
                          <Index.MenuItem value="08">August</Index.MenuItem>
                          <Index.MenuItem value="09">September</Index.MenuItem>
                          <Index.MenuItem value="10">October</Index.MenuItem>
                          <Index.MenuItem value="11">November</Index.MenuItem>
                          <Index.MenuItem value="12">December</Index.MenuItem>
                          <Index.MenuItem value="01">January</Index.MenuItem>
                          <Index.MenuItem value="02">February</Index.MenuItem>
                          <Index.MenuItem value="03">March</Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider">
                    {/* tree map chart */}
                    <TreeMap
                      days={bargeWise}
                      financialYear={currentFinancialYear}
                    />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardDueInvoiceDetails ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Due Invoice Details
                    </Index.Typography>
                    <Index.Box></Index.Box>
                  </Index.Box>
                  {/* 0-15 */}
                  {loadingDueInvoice ? (
                    <>
                      <Index.Box className="chart-graph-devider">
                        <Index.Box className="tree-analysis-main">
                          <Index.Box className="tree-box-loader">
                            <Index.CircularProgress
                              color="secondary"
                              size={20}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ) : (
                    <>
                      <Index.Box className="chart-graph-devider">
                        <Index.Box className="chart-header chart-inner-head 123">
                          <Index.Typography
                            className="chart-title"
                            component="p"
                          >
                            Total Due Invoice
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="total-invoice new-total-invoice">
                          <Index.Box className="card-flex-invoice">
                            <Index.Box
                              className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                              sx={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleInvoiceDetails(
                                  totalInvoice?.["0-15 Days"],
                                  "00_TO_15_DAYS",
                                  moment().format("YYYY-MM-DD"),
                                  moment().add(-15, "days").format("YYYY-MM-DD")
                                );
                              }}
                            >
                              <Index.Typography
                                className="card-total invoice-col-1"
                                component="p"
                              >
                                Due Invoice(0-15 Days)
                              </Index.Typography>
                              <Index.Typography className="card-price invoice-col-2">
                                {totalInvoice?.["0-15 Days"]?.count
                                  ? totalInvoice?.["0-15 Days"]?.count
                                  : "-"}
                              </Index.Typography>
                              <Index.Typography className="card-price invoice-amount invoice-col-3">
                                ₹{" "}
                                {totalInvoice?.hasOwnProperty("0-15 Days")
                                  ? parseFloat(
                                      totalInvoice?.["0-15 Days"]?.total < 0
                                        ? totalInvoice?.["0-15 Days"]?.total *
                                            -1
                                        : totalInvoice?.["0-15 Days"]?.total
                                    )?.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : (0).toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="card-flex-invoice">
                              <Index.Box
                                className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleInvoiceDetails(
                                    totalInvoice?.["16-30 Days"],
                                    "15_TO_30_DAYS",
                                    moment().add(-15, "days").format("YYYY-MM-DD"),
                                    moment().add(-30, "days").format("YYYY-MM-DD")
                                  );
                                }}
                              >
                                <Index.Typography
                                  className="card-total invoice-col-1"
                                  component="p"
                                >
                                  Due Invoice(15-30 Days)
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-col-2">
                                  {totalInvoice?.["16-30 Days"]?.count
                                    ? totalInvoice?.["16-30 Days"]?.count
                                    : "-"}
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-amount invoice-col-3">
                                  ₹{" "}
                                  {totalInvoice?.hasOwnProperty("16-30 Days")
                                    ? parseFloat(
                                        totalInvoice?.["16-30 Days"]?.total < 0
                                          ? totalInvoice?.["16-30 Days"]
                                              ?.total * -1
                                          : totalInvoice?.["16-30 Days"]?.total
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (0).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-flex-invoice">
                              <Index.Box
                                className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleInvoiceDetails(
                                    totalInvoice?.["31-45 Days"],
                                    "30_TO_45_DAYS",
                                    moment().add(-30, "days").format("YYYY-MM-DD"),
                                    moment().add(-45, "days").format("YYYY-MM-DD")
                                  );
                                }}
                              >
                                <Index.Typography
                                  className="card-total invoice-col-1"
                                  component="p"
                                >
                                  Due Invoice(30-45 Days)
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-col-2">
                                  {/* {totalInvoice?.totalInvoice?.count} */}
                                  {totalInvoice?.["31-45 Days"]?.count
                                    ? totalInvoice?.["31-45 Days"]?.count
                                    : "-"}
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-amount invoice-col-3">
                                  ₹{" "}
                                  {totalInvoice?.hasOwnProperty("31-45 Days")
                                    ? parseFloat(
                                        totalInvoice?.["31-45 Days"]?.total < 0
                                          ? totalInvoice?.["31-45 Days"]
                                              ?.total * -1
                                          : totalInvoice?.["31-45 Days"]?.total
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (0).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-flex-invoice">
                              <Index.Box
                                className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleInvoiceDetails(
                                    totalInvoice?.["46-60 Days"],
                                    "45_TO_60_DAYS",
                                    moment().add(-45, "days").format("YYYY-MM-DD"),
                                    moment().add(-60, "days").format("YYYY-MM-DD")
                                  );
                                }}
                              >
                                <Index.Typography
                                  className="card-total invoice-col-1"
                                  component="p"
                                >
                                  Due Invoice(45-60 Days)
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-col-2">
                                  {totalInvoice?.["46-60 Days"]?.count
                                    ? totalInvoice?.["46-60 Days"]?.count
                                    : "-"}
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-amount invoice-col-3">
                                  ₹{" "}
                                  {totalInvoice?.hasOwnProperty("46-60 Days")
                                    ? parseFloat(
                                        totalInvoice?.["46-60 Days"]?.total < 0
                                          ? totalInvoice?.["46-60 Days"]
                                              ?.total * -1
                                          : totalInvoice?.["46-60 Days"]?.total
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (0).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="card-flex-invoice">
                              <Index.Box
                                className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleInvoiceDetails(
                                    totalInvoice?.["61-90 Days"],
                                    "60_TO_90_DAYS",
                                    moment().add(-60, "days").format("YYYY-MM-DD"),
                                    moment().add(-90, "days").format("YYYY-MM-DD")
                                  );
                                }}
                              >
                                <Index.Typography
                                  className="card-total invoice-col-1"
                                  component="p"
                                >
                                  Due Invoice (60-90 Days)
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-col-2">
                                  {/* {totalInvoice?.totalInvoice?.count} */}
                                  {totalInvoice?.["61-90 Days"]?.count
                                    ? totalInvoice?.["61-90 Days"]?.count
                                    : "-"}
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-amount invoice-col-3">
                                  ₹{" "}
                                  {totalInvoice?.hasOwnProperty("61-90 Days")
                                    ? parseFloat(
                                        totalInvoice?.["61-90 Days"]?.total < 0
                                          ? totalInvoice?.["61-90 Days"]
                                              ?.total * -1
                                          : totalInvoice?.["61-90 Days"]?.total
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (0).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="card-flex-invoice">
                              <Index.Box
                                className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                                sx={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  handleInvoiceDetails(
                                    totalInvoice?.["Above 90 Days"],
                                    "GREATER_90_DAYS",
                                    moment().add(-90, "days").format("YYYY-MM-DD"),
                                    ""
                                  );
                                }}
                              >
                                <Index.Typography
                                  className="card-total invoice-col-1"
                                  component="p"
                                >
                                  Due Invoice (90 &lt; Days)
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-col-2">
                                  {/* {totalInvoice?.totalInvoice?.count} */}
                                  {totalInvoice?.["Above 90 Days"]?.count
                                    ? totalInvoice?.["Above 90 Days"]?.count
                                    : "-"}
                                </Index.Typography>
                                <Index.Typography className="card-price invoice-amount invoice-col-3">
                                  ₹{" "}
                                  {totalInvoice?.hasOwnProperty("Above 90 Days")
                                    ? parseFloat(
                                        totalInvoice?.["Above 90 Days"]?.total <
                                          0
                                          ? totalInvoice?.["Above 90 Days"]
                                              ?.total * -1
                                          : totalInvoice?.["Above 90 Days"]
                                              ?.total
                                      )?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : (0).toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardBankData ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Bank List
                    </Index.Typography>
                    <Index.Box></Index.Box>
                  </Index.Box>
                  {/* 0-15 */}
                  <AllDashboardBankData />
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardBankTransactions ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Bank Transactions
                    </Index.Typography>
                    <Index.Box></Index.Box>
                  </Index.Box>
                  {/* 0-15 */}
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <DashboardBankTransactions />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardCategoryWise ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Category Wise
                    </Index.Typography>
                    {/* <Index.Box
                      className=""
                      width="50%"
                      display="flex"
                      justifyContent="end"
                      gap="10px"
                    >
                      <Index.FormControl className="main-select-graph m-2">
                        <Index.Select
                          value={currentFinancialYearForCategory}
                          // defaultValue={currentFinancialYear}
                          onChange={(e) =>
                            // handlceChangeFinancialYear(e.target.value)
                            setCurrentFinalcialYearForCategory(e.target.value)
                          }
                          className="select-graph"
                        >
                          {financialYearOptions?.map((el) => (
                            <Index.MenuItem value={el.value}>
                              {el.label}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormControl
                        className="main-select-graph"
                        sx={{ minWidth: 80 }}
                      >
                        <Index.InputLabel id="demo-simple-select-helper-label"></Index.InputLabel>
                        <Index.Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={categoryWise}
                          onChange={categoryHandleChange}
                          className="select-graph"
                        >
                          <Index.MenuItem value={1}>January</Index.MenuItem>
                          <Index.MenuItem value={2}>February</Index.MenuItem>
                          <Index.MenuItem value={3}>March</Index.MenuItem>
                          <Index.MenuItem value={4}>April</Index.MenuItem>
                          <Index.MenuItem value={5}>May</Index.MenuItem>
                          <Index.MenuItem value={6}>June</Index.MenuItem>
                          <Index.MenuItem value={7}>July</Index.MenuItem>
                          <Index.MenuItem value={8}>August</Index.MenuItem>
                          <Index.MenuItem value={9}>September</Index.MenuItem>
                          <Index.MenuItem value={10}>October</Index.MenuItem>
                          <Index.MenuItem value={11}>November</Index.MenuItem>
                          <Index.MenuItem value={12}>December</Index.MenuItem>
                          <Index.MenuItem value={"all"}>All</Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box> */}
                  </Index.Box>
                  <CategoryTreeMap
                    days={categoryWise}
                    financialYear={currentFinancialYearForCategory}
                  />
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardTop10SalesPartyWise ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      Top 10 Sales Party Wise
                    </Index.Typography>
                    {/* <Index.Box
                      className=""
                      width="50%"
                      display="flex"
                      justifyContent="end"
                      gap="10px"
                    >
                      <Index.FormControl className="main-select-graph m-2">
                        <Index.Select
                          value={currentFinancialYearForSales}
                          // defaultValue={currentFinancialYear}
                          onChange={(e) =>
                            // handlceChangeFinancialYear(e.target.value)
                            setCurrentFinancialYearForSales(e.target.value)
                          }
                          className="select-graph"
                        >
                          {financialYearOptions?.map((el) => (
                            <Index.MenuItem value={el.value}>
                              {el.label}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormControl
                        className="main-select-graph"
                        sx={{ minWidth: 80 }}
                      >
                        <Index.InputLabel id="demo-simple-select-helper-label"></Index.InputLabel>
                        <Index.Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={partyWise}
                          onChange={partyWiseHandleChange}
                          className="select-graph"
                        >
                          <Index.MenuItem value={1}>January</Index.MenuItem>
                          <Index.MenuItem value={2}>February</Index.MenuItem>
                          <Index.MenuItem value={3}>March</Index.MenuItem>
                          <Index.MenuItem value={4}>April</Index.MenuItem>
                          <Index.MenuItem value={5}>May</Index.MenuItem>
                          <Index.MenuItem value={6}>June</Index.MenuItem>
                          <Index.MenuItem value={7}>July</Index.MenuItem>
                          <Index.MenuItem value={8}>August</Index.MenuItem>
                          <Index.MenuItem value={9}>September</Index.MenuItem>
                          <Index.MenuItem value={10}>October</Index.MenuItem>
                          <Index.MenuItem value={11}>November</Index.MenuItem>
                          <Index.MenuItem value={12}>December</Index.MenuItem>
                          <Index.MenuItem value={"all"}>All</Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box> */}
                  </Index.Box>
                  <SalesTreeMap
                    days={partyWise}
                    financialYear={currentFinancialYearForSales}
                  />
                </Index.Box>
              </>
            ) : (
              <></>
            )}
          </Index.Box>
          {webPermission?.dashboardOrderView ||
          webPermission?.dashboardInBreakdown ||
          webPermission?.dashboardDocumentCenter ||
          webPermission?.dashboardHDFCBANK ||
          webPermission?.dashboardKOTAKBANK ||
          webPermission?.dashboardElectrical ||
          webPermission?.dashboardMechanical ||
          webPermission?.dashboardFabrication ||
          webPermission?.dashboardProcurement ? (
            <>
              <Index.Box className=" heading-line-wrapper">
                <Index.Box className="heading-line">
                  <Index.Typography className="head-title mr-20" component="h1">
                    Operation & Maintenance
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </>
          ) : (
            <></>
          )}

          <Index.Box className="chart-main-wrapp category-sec-main chart-main-wrapp">
            {webPermission?.dashboardOrderView ? (
              <>
                <Index.Box className="chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography className="chart-title" component="p">
                      {/* Sales Port Wise */}
                      Order View
                    </Index.Typography>
                    <Index.Box>
                      <Index.FormControl
                        className="main-select-graph"
                        sx={{ minWidth: 80 }}
                      >
                        <Index.InputLabel id="demo-simple-select-helper-label"></Index.InputLabel>
                        <Index.Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={orderViewFilterOption}
                          onChange={(e) => {
                            setOrderViewFilterOption(e.target.value);
                          }}
                          className="select-graph"
                        >
                          <Index.MenuItem value="today">Today</Index.MenuItem>
                          <Index.MenuItem value="ongoing">
                            Ongoing
                          </Index.MenuItem>
                          <Index.MenuItem value="completed">
                            Completed
                          </Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider map-image">
                    <OrderView filterOption={orderViewFilterOption} />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardInBreakdown ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      In Breakdown
                    </Index.Typography>
                    <Index.Box>
                      <Index.FormControl
                        className="main-select-graph"
                        sx={{ minWidth: 80 }}
                      >
                        <Index.InputLabel id="demo-simple-select-helper-label"></Index.InputLabel>
                        <Index.Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={breakdownFilterOption}
                          onChange={(e) => {
                            setBreakdownFilterOption(e.target.value);
                          }}
                          className="select-graph"
                        >
                          <Index.MenuItem value="Craft">Craft</Index.MenuItem>
                          <Index.MenuItem value="Tanker">Tanker</Index.MenuItem>
                        </Index.Select>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <BreakDwonDetails
                      breakDownOptionValue={breakdownFilterOption}
                    />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardDocumentCenter ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      Document Center
                      <span className="dash-fin-year">
                        ({`${currentMonthName}-${currentYearInNumber}`})
                      </span>
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <DocumentDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardHDFCBANK ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      HDFC BANK
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <HDFCBankDataDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardKOTAKBANK ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      KOTAK BANK
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <KotakBankDataDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}

            {webPermission?.dashboardElectrical ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      Electrical
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <ElectricalDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
            {webPermission?.dashboardMechanical ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      Mechanical
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <MechanicalDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
            {webPermission?.dashboardFabrication ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      Fabrication
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <FabricationDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
            {webPermission?.dashboardProcurement ? (
              <>
                <Index.Box className="chart-box tanker-chart-box">
                  <Index.Box className="chart-header">
                    <Index.Typography
                      className="chart-title tanker-dashboard-title"
                      component="p"
                    >
                      Procurement
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="chart-graph-devider tanker-dash-table">
                    <ProcurementDetails />
                  </Index.Box>
                </Index.Box>
              </>
            ) : (
              <></>
            )}
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
};

export default CompanyDash;
