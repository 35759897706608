import { useSearchParams } from "react-router-dom";
import Index from "../../../Index";
import { useEffect, useState } from "react";
import { getBetaJournalTallyInvoiceDetails } from "../../../../redux/admin/action";

function BetaJournalDetails() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [searchParams] = useSearchParams();
  let { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);

  console.log("searchParams", searchParams.get("companyName"));

  //   when the get invoice details then used...
  const handleGetInvoiceDetails = () => {
    setLoading(true);
    dispatch(
      getBetaJournalTallyInvoiceDetails({
        guid: searchParams.get("guid"),
        companyName: searchParams.get("companyName"),
      })
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setInvoiceDetails(res.data);
        } else {
          setLoading(false);
          setInvoiceDetails([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setInvoiceDetails([]);
        console.error(error);
      });
  };

  //   when the page first time called then used...
  useEffect(() => {
    handleGetInvoiceDetails();
  }, []);

  //   when the user want to back then used...
  const backBtn = () => {
    navigate("/admin/purchaseBeta");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex">
            <Index.Box className="title-main mb-10">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Invoice Details
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button save-btn ">
              <Index.Button
                variant="contained"
                type="submit"
                className="Particular_model_submit ml-0"
                onClick={backBtn}
              >
                Back
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="invoice-details">
          <Index.Box className="barge-common-box mb-20">
            <Index.Grid container spacing={1}>
              <Index.Grid item xs={12} sm={6}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Voucher No:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {searchParams.get("voucherName")
                      ? searchParams.get("voucherName")
                      : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Party Name:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {searchParams.get("partyName")
                      ? searchParams.get("partyName")
                      : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              {/* <Index.Grid item xs={12} sm={6}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Company Name:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {searchParams.get("companyName")
                      ? searchParams.get("companyName")
                      : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid> */}

              <Index.Grid item xs={12} sm={6}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Date:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {searchParams.get("date")
                      ? Index.moment(searchParams.get("date")).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
        <Index.TableContainer
          component={Index.Paper}
          className="table-container"
        >
          <Index.Table
            aria-label="simple table"
            className="table-design-main invoice-table invoice-table-set  "
          >
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell className="invoice_table_header">
                  Amount
                </Index.TableCell>
                <Index.TableCell align="left" className="invoice_table_header">
                  Amount Forex
                </Index.TableCell>
                <Index.TableCell align="left" className="invoice_table_header">
                  Ledger
                </Index.TableCell>
                <Index.TableCell align="left" className="invoice_table_header">
                  Company Name
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>

            {loading ? (
              <Index.TableBody>
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={15}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableBody>
            ) : (
              <Index.TableBody>
                {invoiceDetails?.length ? (
                  invoiceDetails?.map((row, index) => (
                    <Index.TableRow key={row?.guid + (index + 1)}>
                      <Index.TableCell className="invoice-view-cursor">
                        {row?.amount ? row?.amount : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.amount_forex ? row?.amount_forex : "-"}
                      </Index.TableCell>{" "}
                      <Index.TableCell>
                        {row?.ledger ? row?.ledger : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.company_name ? row?.company_name : "-"}
                      </Index.TableCell>
                    </Index.TableRow>
                  ))
                ) : (
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="no-data-in-list empty-record-align"
                      colSpan={19}
                      align="center"
                    >
                      No data available
                    </Index.TableCell>
                  </Index.TableRow>
                )}
              </Index.TableBody>
            )}
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}

export default BetaJournalDetails;
