import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  addChallanEditReqStatus,
  addChallanISEditable,
  addChallanStatus,
  getChallanList,
  getInvoiceActivityLog,
  getSingleChallanEditRequest,
  getSingleOrder,
  getLoadingOrderTankerDetailsRequest,
  cancelOrder,
  getNextCurrentOrder,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
import OrderDocument from "./OrderDocument";
import OrderStatusModel from "../../../../component/common/OrderStatusModel";
import TankerShiftModel from "./TankerShiftModel";
import PipeLineDetailsPage from "./PipeLineDetailsPage";
import JSZip from "jszip";
import { toast } from "react-toastify";
import OrderTransfer from "./OrderViewTabComponents/OrderTransfer";
import SurveyourSupplyTiming from "./SurveyourSupplyTiming";
import PipelineSurveyourSupplyTiming from "./PipelineSurveyourSupplyTiming";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//  style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SingleOrder() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { state } = useLocation();
  const tabType = state?.type;
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  // state
  const [viewData, setViewData] = useState({});
  const [challanData, setChallanData] = useState([]);
  const [challanImg, setChallanImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const [open, setOpen] = useState(false);
  const [challanRequestData, setChallanRequestData] = useState([]);

  const [showModal, setShowModal] = useState(true);
  const [isShifted, setIsShifted] = useState(false);
  const [tankerLoadingDetail, setTankerLoadingDetail] = useState({});
  const [loadingTankers, setLoadingTankers] = useState([]);
  const [removedTankerCount, setRemovedTankerCount] = useState(0);
  const [openModalId, setOpenModalId] = useState();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [status, setStatus] = useState(true);

  const [tankerOpen, setTankerOpen] = useState(false);
  const [loadingSingleData, setLoadingSingleData] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);

  // state for checkbox
  const [tankerCheckId, setTankerCheckId] = useState([]);

  const totalLoadedQuantity = loadingTankers?.length
    ? loadingTankers.reduce(
        (sum, item) =>
          item.loadedQty !== null &&
          item.loadedQty !== undefined &&
          item?.hasOwnProperty("loadedQty") &&
          typeof item?.loadedQty === "number"
            ? sum + parseFloat(item.loadedQty)
            : sum + 0,
        0
      )
    : 0;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let singleData = state?.row;
  const backBtn = () => {
    navigate("/admin/order");
    if (tabType === "cancelOrder") {
      navigate("/admin/order", { state: { value: 1 } });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/order", { state: { value: 2 } });
    }
  };

  const handleEdit = (row) => {
    navigate(`/admin/order/edit/${row?._id}`, {
      state: {
        row,
      },
    });
    // navigate("/admin/order/add", {
    //   state: { row },
    // });
    if (tabType === "cancelOrder") {
      navigate("/admin/order/add", {
        state: { row, type: "cancelOrder" },
      });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/order/add", {
        state: { row, type: "completedOrder" },
      });
    }
  };

  const handleOpen = (img) => {
    setOpen(true);
    setChallanImg(img);
  };
  const handleClose = () => setOpen(false);

  const getSingleOrderList = () => {
    setLoadingSingleData(true);
    dispatch(getSingleOrder({ id })).then((res) => {
      setViewData(res?.data);
      if (res?.status === 200) {
        getOrderLog();
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      }
    });
  };

  // status approved
  const statusApproved = (item) => {
    let data = {
      id: item?._id,
      status: "Approve",
    };
    dispatch(addChallanStatus(data)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
        getOrderLog();
      }
    });
  };

  // status Reject
  const statusRejected = (item) => {
    let data = {
      id: item?._id,
      status: "Reject",
    };
    dispatch(addChallanStatus(data)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
        getOrderLog();
      }
    });
  };

  // edit challan request approved
  const challanEditRequestStatusApproved = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Approved",
    };
    dispatch(addChallanEditReqStatus(data)).then((res) => {
      if (res?.status === 200) {
        singleChallanEditRequest();
        getOrderLog();
      }
    });
  };

  const showTankerDetails = (item, action) => {
    setOpenModalId(item?._id);
    if (openModalId === item?._id && action === "down") {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
    setTankerLoadingDetail(item);
  };

  // edit challan request reject
  const challanEditRequestStatusReject = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Rejected",
    };
    dispatch(addChallanEditReqStatus(data)).then((res) => {
      if (res?.status === 200) {
        singleChallanEditRequest();
        getOrderLog();
      }
    });
  };

  const getAllChallanList = () => {
    const data = new URLSearchParams();
    data.append("orderId", id);
    dispatch(getChallanList(data)).then((res) => {
      if (res?.status === 200) {
        setChallanData(res?.data);
      } else {
        setChallanData([]);
      }
      getOrderLog();
    });
  };

  const getLoadingTankerDetails = (id) => {
    const data = new URLSearchParams();
    data.append("id", id); // order id
    dispatch(getLoadingOrderTankerDetailsRequest(data)).then((res) => {
      setLoadingTankers(res?.data);
      setRemovedTankerCount(res?.removedTanker);
      setIsShifted(false);
    });
  };

  // get challan list
  useEffect(() => {
    getAllChallanList();
    getLoadingTankerDetails(id); // Loading tanker details
    getSingleOrderList();
  }, [isShifted]);

  // filter for activity log
  const filterOrderLogData = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });

  const getOrderLog = () => {
    const logData = {
      activityTypeId: id,
      type: "order",
    };
    dispatch(getInvoiceActivityLog(logData));
  };

  useEffect(() => {
    getOrderLog();
  }, []);

  const handleChallanISEditable = (id) => {
    const data = new URLSearchParams();
    setLoadingRowId(id);
    setLoading(true);
    data.append("id", id);
    dispatch(addChallanISEditable(data)).then((res) => {
      if (res === 200 || 201) {
        getAllChallanList();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  // get single challan edit request
  const singleChallanEditRequest = () => {
    if (challanData?.length > 0) {
      const challanID = challanData?.[0]?._id;
      const data = new URLSearchParams();
      data.append("id", challanID);
      dispatch(getSingleChallanEditRequest(data)).then((res) => {
        setChallanRequestData(res?.data);
      });
    }
  };
  useEffect(() => {
    if (challanData?.length > 0) {
      singleChallanEditRequest();
    }
  }, [challanData]);

  const handleEditBunkerTiming = (row) => {
    row[0].orderId = id;
    navigate("/admin/order/bunker-timing", {
      state: { row, type: "editBunker", value: value },
    });
  };

  // cancel order popup
  const cancelOrderModelOpen = () => {
    // setAnchorEl(null);
    setCancelOpen(true);
  };

  const cancelOrderModelClose = () => {
    setCancelOpen(false);
    // setStatus(true);
  };

  // cancel Order button
  const cancelOrderBtn = (status, comment) => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("cancelStatus", "Cancel");
    data.append("comment", comment);

    dispatch(cancelOrder(data, navigate));
  };

  // model open for shift tanker
  const shiftTankerHandleOpen = (id) => {
    setTankerOpen(true);
  };
  const shiftTankerHandleClose = () => {
    setTankerOpen(false);
  };

  // tanker dahboard view page
  const handleTankerDashboardView = (id, loadingType) => {
    navigate(`/admin/tankers/dashboard/view/${id}`, {
      state: { type: loadingType },
    });
  };

  // when the user want to download the images then used...
  // when the user want to download the images then used...
  const handleDownloadImage = (data, fileName) => {
    if (data?.document?.length) {
      if (data?.document?.length > 1) {
        let imageURLs = [];
        data?.document?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/loadingDoc/${item}`,
            newFileName: `${fileName}_${item},`,
          });
        });

        if (imageURLs?.length) {
          const zip = new JSZip();

          // Fetch each image and add it to the zip file
          const addImageToZip = async (url, index) => {
            const response = await fetch(url.path);
            const arrayBuffer = await response.arrayBuffer();
            const extension = url?.path?.substring(
              url?.path?.lastIndexOf(".") + 1,
              url?.path?.length
            );
            zip.file(
              `${url?.newFileName}-${index + 1}.${extension}`,
              arrayBuffer
            );
          };

          // Add all images to the zip file
          Promise.all(imageURLs.map(addImageToZip))
            .then(() => {
              // Generate the zip file and initiate the download
              zip.generateAsync({ type: "blob" }).then((content) => {
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(content);
                downloadLink.download = `${fileName}-documents.zip`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              });
            })
            .catch((error) => {
              console.error("Error adding images to zip:", error);
            });
        } else {
          toast.error("Document Doesn't Exist");
        }
      } else {
        // when the single document then used...
        const imageUrl = `${process.env.REACT_APP_IMAGE_URL}/loadingDoc/${data.document[0]}`;
        const imageName = `${fileName}_${data.document[0]}`;
        fetch(imageUrl, {
          method: "GET",
          headers: {},
        })
          .then((response) => {
            response.arrayBuffer().then(function (buffer) {
              const url = window.URL.createObjectURL(new Blob([buffer]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", imageName); //or any other extension
              document.body.appendChild(link);
              link.click();
              // setDocumentLoading(false)
            });
          })
          .catch((err) => {
            toast.error("Document Doesn't Exist");
          });
      }
    } else {
      toast.error("Document Doesn't Exist");
    }
  };

  // when the user download all the images then used...
  const handleDownload = () => {
    if (loadingTankers?.length) {
      let imageURLs = [];
      loadingTankers?.forEach((el) => {
        el?.document?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/loadingDoc/${item}`,
            fileName: `${el?.tanker?.tankerNumber}_${item}`,
          });
        });
      });

      if (imageURLs?.length) {
        setDocumentLoading(true);
        const zip = new JSZip();

        // Fetch each image and add it to the zip file
        const addImageToZip = async (url, index) => {
          const response = await fetch(url?.path);
          const arrayBuffer = await response.arrayBuffer();
          const extension = url?.path.substring(
            url?.path.lastIndexOf(".") + 1,
            url?.path.length
          );
          zip.file(`${url?.fileName}-${index + 1}.${extension}`, arrayBuffer);
        };

        // Add all images to the zip file
        Promise.all(imageURLs.map(addImageToZip))
          .then(() => {
            // Generate the zip file and initiate the download
            zip.generateAsync({ type: "blob" }).then((content) => {
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.download = "All-TT-documents.zip";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              setDocumentLoading(false);
            });
          })
          .catch((error) => {
            console.error("Error adding documents to zip:", error);
            setDocumentLoading(false);
          });
      } else {
        toast.error("Document Doesn't Exist");
        setDocumentLoading(false);
      }
    } else {
      toast.error("Document Doesn't Exist");
      setDocumentLoading(false);
    }
  };

  // when the user go to next order then used...
  const handleGoToNextOrder = (orderData) => {
    dispatch(getNextCurrentOrder({ id: orderData?._id }))
      .then((res) => {
        if (res.status === 200) {
          window.open(`/admin/order/view-order/${res?.data?._id}`, "_blank");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Order Details" />
      {loadingSingleData ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <>
          <Index.Box className="invoice-details">
            <Index.Box className="barge-common-box mb-20">
              <Index.Box className="title-header-flex flex-column-res">
                <Index.Box className="invoice-back-title">
                  <Index.Button
                    type="submit"
                    className="Particular_model_submit back-invoice-btn"
                    onClick={backBtn}
                  >
                    <Index.ArrowBackIcon />
                  </Index.Button>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="invoice-title"
                  >
                    {/* Order Detail */}
                    {tabType === "cancelOrder"
                      ? "Cancelled Order Details"
                      : tabType === "completedOrder"
                      ? "Completed Order Details"
                      : "Order Details"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-button blue-button save-btn res-button-width">
                  {/* <Index.Button
                    variant="contained"
                    disableRipple
                    // className="Particular_model_submit edit-invoice-btn"
                    onClick={() => handleGoToNextOrder(viewData)}
                  >
                    Next Order
                  </Index.Button> */}
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit edit-invoice-btn"
                    onClick={() => handleEdit(viewData)}
                  >
                    <Index.EditIcon />
                  </Index.Button>
                  {viewData?.orderStatus !== "Cancel" ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        cancelOrderModelOpen();
                      }}
                    >
                      Cancel Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Grid container spacing={1}>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Nomination No.:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.nominationNo ? viewData?.nominationNo : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Enquiry No.:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.inquiryNo ? viewData?.inquiryNo : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Nomination Receiving Date:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.nomination_rec_date
                        ? Index.moment(viewData?.nomination_rec_date).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Vessel Name:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.vesselName ? viewData?.vesselName : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Port:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.port?.portName
                        ? viewData?.port?.portName
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Exmi Or Delivered:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.exmiOrDelivered
                        ? viewData?.exmiOrDelivered
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Wharfage PDF:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.wharfageFile &&
                        viewData?.wharfageFile.map((item, index) => (
                          <>
                            <Index.Box className="pdf-input Wharfage-pdf">
                              <Index.Box
                                className="download-pdf-pd"
                                key={index}
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_IMAGE_URL}/wharfage/${item}`
                                  );
                                }}
                              >
                                {index + 1}. {item ? item : "-"}
                              </Index.Box>
                            </Index.Box>
                          </>
                        ))}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      PI to Loading Start:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.durationOfUploadedNomination
                        ? viewData?.durationOfUploadedNomination
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>

                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Total Quantity:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.lowerQuantity ? viewData?.lowerQuantity : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Loading Completed Quantity:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.loadingCompletedQuantity
                        ? viewData?.loadingCompletedQuantity
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Status:
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.orderStatusForTrader
                        ? viewData?.orderStatusForTrader
                        : "-"}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Proforma Invoice PDF:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.nominationPdf && (
                      <Index.Button
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_IMAGE_URL}/orders/${viewData?.nominationPdf}`
                          );
                        }}
                      >
                        {viewData?.nominationPdf
                          ? viewData?.nominationPdf
                          : "-"}
                      </Index.Button>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Box>

            <Index.Box className="mb-20">
              {/* Design Tab */}
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  className="tab-button-box"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="dashboard-tab order-detail-tab"
                  >
                    <Tab
                      className="tab-button"
                      label="Details"
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="tab-button"
                      label="Documents"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="tab-button"
                      label="Challan List"
                      {...a11yProps(2)}
                    />

                    <Tab
                      className="tab-button"
                      label="Bunker Timing"
                      {...a11yProps(3)}
                    />
                    {viewData?.transportation === "PIPELINE" ? (
                      <Tab
                        className="tab-button"
                        label=" Pipeline Orders Surveyor Supply Timing "
                        {...a11yProps(4)}
                      />
                    ) : (
                      <Tab
                        className="tab-button"
                        label="Surveyor Supply Timing"
                        {...a11yProps(4)}
                      />
                    )}

                    <Tab
                      className="tab-button"
                      label=" Challan Edit Request"
                      {...a11yProps(5)}
                    />

                    {viewData?.transportation !== "PIPELINE" ? (
                      <Tab
                        className="tab-button"
                        label="Loading"
                        {...a11yProps(6)}
                      />
                    ) : (
                      <Tab
                        className="tab-button"
                        label="Pipeline"
                        {...a11yProps(6)}
                      />
                    )}

                    <Tab
                      className="tab-button"
                      label="Transfer"
                      {...a11yProps(7)}
                    />
                    <Tab
                      className="tab-button"
                      label="Activity"
                      {...a11yProps(8)}
                    />
                  </Tabs>
                </Index.Box>
                <SingleOrderTab
                  value={value}
                  index={0}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Grid container spacing={1}>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Order Received From / Trader:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.traderId?.traderName
                            ? viewData?.traderId?.traderName
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Terminal:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.terminal ? viewData?.terminal : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          C/F:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.foreign ? viewData?.foreign : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Nominated Qty (In MT):
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.lowerQuantity === "0" &&
                          viewData?.higherQuantity === "0"
                            ? "-"
                            : viewData?.lowerQuantity &&
                              viewData?.higherQuantity !== "0"
                            ? viewData?.lowerQuantity +
                              " - " +
                              viewData?.higherQuantity
                            : viewData?.lowerQuantity}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Product Name:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.product ? viewData?.product : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Agent:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.agent ? viewData?.agent : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          ETA for Vessel:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.laycam
                            ? Index.moment(viewData?.laycam).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Laycan From to To :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.layStartDate && viewData?.layEndDate ? (
                            <>
                              {Index.moment(viewData?.layStartDate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              -
                              {Index.moment(viewData?.layEndDate).format(
                                "DD/MM/YYYY"
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          ETB:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.etb
                            ? Index.moment(viewData?.etb).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          ETD:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.etd
                            ? Index.moment(viewData?.etd).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Loading Planned Date:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.loadingDate
                            ? Index.moment(viewData?.loadingDate).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Supply Date:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.supplyDate
                            ? Index.moment(viewData?.supplyDate).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Basis Taken On:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.basisTakenOn
                            ? Index.moment(viewData?.basisTakenOn).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Proposed Barge:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.proposedBarge?.length
                            ? viewData?.proposedBarge
                              ? Array.prototype.map
                                  .call(
                                    viewData?.proposedBarge,
                                    function (ele) {
                                      return ele.bargeName;
                                    }
                                  )
                                  .join(",")
                              : ""
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Company:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.companyName ? viewData?.companyName : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Transportation:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.transportation
                            ? viewData?.transportation
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Stay Hours:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.stayHours ? viewData?.stayHours : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Status:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.status ? viewData?.status : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Cancel Comment:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.comment ? viewData?.comment : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Bunker Call Status:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.bunkerCall === true ? "Yes" : "No"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={1}
                  className="barge-common-box tab-content-box"
                >
                  <OrderDocument
                    id={id}
                    viewData={viewData}
                    setViewData={setViewData}
                  />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={2}
                  className="barge-common-box tab-content-box"
                >
                  {challanData?.length ? (
                    challanData &&
                    challanData?.map((items, index) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            Trip {index + 1}{" "}
                            {Index.moment(items?.createdAt).format(
                              "DD/MM/YYYY h:mm:ss a"
                            )}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Index.TableContainer>
                              <Index.Table className="challan-table challan-table-set challan-table-font challan-table-header challan-table-main">
                                <Index.TableHead>
                                  <Index.TableRow>
                                    <Index.TableCell>
                                      Created Date
                                    </Index.TableCell>
                                    <Index.TableCell className="img-header-align">
                                      Image
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      Updated Date
                                    </Index.TableCell>
                                    <Index.TableCell>Status</Index.TableCell>
                                    <Index.TableCell>
                                      Approve / Reject
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody>
                                  <Index.TableRow>
                                    <Index.TableCell>
                                      <Index.Typography>
                                        {Index.moment(items?.createdAt).format(
                                          "DD/MM/YYYY h:mm:ss a"
                                        )}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      className={
                                        items?.image?.length
                                          ? "padding-zero"
                                          : ""
                                      }
                                    >
                                      {items?.image?.length ? (
                                        <>
                                          <div>
                                            <Index.Button
                                              onClick={() => {
                                                handleOpen(items?.image);
                                              }}
                                            >
                                              <img
                                                src={`${
                                                  process.env
                                                    .REACT_APP_IMAGE_URL
                                                }/challans/${
                                                  items?.image
                                                    ? items?.image
                                                    : "-"
                                                }`}
                                                alt="Image Preview"
                                                className="challan-img-model"
                                              />
                                            </Index.Button>
                                            <Index.Modal
                                              open={open}
                                              onClose={handleClose}
                                              aria-labelledby="modal-modal-title"
                                              aria-describedby="modal-modal-description"
                                            >
                                              <Index.Box sx={style}>
                                                <Index.ClearIcon
                                                  className="model-close-icon"
                                                  onClick={handleClose}
                                                />
                                                <Index.Typography
                                                  id="modal-modal-title"
                                                  variant="h6"
                                                  component="h2"
                                                >
                                                  <img
                                                    src={`${
                                                      process.env
                                                        .REACT_APP_IMAGE_URL
                                                    }/challans/${
                                                      challanImg
                                                        ? challanImg
                                                        : ""
                                                    }`}
                                                    alt="Image Preview"
                                                    className="challan-img"
                                                  />
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Modal>
                                          </div>
                                        </>
                                      ) : (
                                        <div>-</div>
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      <Index.Typography>
                                        {Index.moment(items?.updatedAt).format(
                                          "DD/MM/YYYY h:mm:ss a"
                                        )}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      {items?.status?.length
                                        ? items?.status === "Approve"
                                          ? "Approved"
                                          : items?.status === "Reject"
                                          ? "Rejected"
                                          : "-"
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell>
                                      <Index.Button
                                        variant="contained"
                                        onClick={() => statusApproved(items)}
                                        className="challan-status btn-approve"
                                        disabled={items?.statusAction === true}
                                      >
                                        Approve
                                      </Index.Button>
                                      <Index.Button
                                        variant="contained"
                                        align="left"
                                        className="challan-status reject-btn-align btn-reject"
                                        onClick={() => statusRejected(items)}
                                        disabled={items?.statusAction === true}
                                      >
                                        Reject
                                      </Index.Button>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </AccordionDetails>
                        </Accordion>
                        // <Index.TableRow>
                        //   <Index.TableCell>
                        //     <Index.Typography>
                        //       {Index.moment(items?.createdAt).format(
                        //         "DD/MM/YYYY h:mm:ss a"
                        //       )}
                        //     </Index.Typography>
                        //   </Index.TableCell>
                        //   <Index.TableCell style={{ padding: 0 }}>
                        //     <div>
                        //       <Index.Button
                        //         onClick={() => {
                        //           handleOpen(items?.image);
                        //         }}
                        //       >
                        //         <img
                        //           src={`${
                        //             process.env.REACT_APP_IMAGE_URL
                        //           }/challans/${
                        //             items?.image ? items?.image : "-"
                        //           }`}
                        //           alt="Image Preview"
                        //           className="challan-img-model"
                        //         />
                        //       </Index.Button>
                        //       <Index.Modal
                        //         open={open}
                        //         onClose={handleClose}
                        //         aria-labelledby="modal-modal-title"
                        //         aria-describedby="modal-modal-description"
                        //       >
                        //         <Index.Box sx={style}>
                        //           <Index.ClearIcon
                        //             className="model-close-icon"
                        //             onClick={handleClose}
                        //           />
                        //           <Index.Typography
                        //             id="modal-modal-title"
                        //             variant="h6"
                        //             component="h2"
                        //           >
                        //             <img
                        //               src={`${
                        //                 process.env.REACT_APP_IMAGE_URL
                        //               }/challans/${
                        //                 challanImg ? challanImg : ""
                        //               }`}
                        //               alt="Image Preview"
                        //               className="challan-img"
                        //             />
                        //           </Index.Typography>
                        //         </Index.Box>
                        //       </Index.Modal>
                        //     </div>
                        //   </Index.TableCell>
                        //   <Index.TableCell>
                        //     <Index.Typography>
                        //       {Index.moment(items?.updatedAt).format(
                        //         "DD/MM/YYYY h:mm:ss a"
                        //       )}
                        //     </Index.Typography>
                        //   </Index.TableCell>
                        //   <Index.TableCell>
                        //     {items?.status?.length
                        //       ? items?.status === "Approve"
                        //         ? "Approved"
                        //         : items?.status === "Reject"
                        //         ? "Rejected"
                        //         : "-"
                        //       : "-"}
                        //   </Index.TableCell>
                        //   <Index.TableCell>
                        //     <Index.Button
                        //       variant="contained"
                        //       onClick={() => statusApproved(items)}
                        //       className="challan-status btn-approve"
                        //       disabled={items?.statusAction === true}
                        //     >
                        //       Approve
                        //     </Index.Button>
                        //     <Index.Button
                        //       variant="contained"
                        //       align="left"
                        //       className="challan-status reject-btn-align btn-reject"
                        //       onClick={() => statusRejected(items)}
                        //       disabled={items?.statusAction === true}
                        //     >
                        //       Reject
                        //     </Index.Button>
                        //   </Index.TableCell>
                        // </Index.TableRow>
                      );
                    })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={24}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                  {/* </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer> */}
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={3}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Box className="common-button blue-button save-btn res-button-width res-button-add-set">
                    {challanData?.[0]?._id ? (
                      <>
                        <Index.Button
                          className="set-active-btn"
                          variant="contained"
                          type="submit"
                          onClick={() => handleEditBunkerTiming(challanData)}
                        >
                          Edit Bunker Timing
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="mt-20">
                    {/* <Index.Grid container spacing={2}> */}
                    {challanData?.length ? (
                      challanData &&
                      challanData?.map((items, index) => {
                        return (
                          <>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                Trip {index + 1}{" "}
                                {Index.moment(items?.createdAt).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )}
                              </AccordionSummary>
                              <AccordionDetails>
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Pumping Rate (In Hours):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.pumpingRate
                                              ? items?.pumpingRate
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Craft Speed (Nautical Mileage):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.craftSpeed
                                              ? items?.craftSpeed
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Distance (In Nautical Miles):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.distance
                                              ? items?.distance
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Diesel Consumption:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.dieselConsumption
                                              ? items?.dieselConsumption
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Supply Docs Received On Board:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.supplyDocsReceivedOnBoard ===
                                            false
                                              ? "No"
                                              : "Yes"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Anchor Aweigh:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.anchorAweighForSupply
                                              ? Index.moment(
                                                  items?.anchorAweighForSupply
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Alongside On Vessel:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.alongsideOnVessel
                                              ? Index.moment(
                                                  items?.alongsideOnVessel
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Pumping Start:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.pumpingStart
                                              ? Index.moment(
                                                  items?.pumpingStart
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Pumping Completed:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {/* {items?.pumpingCompleted} */}
                                            {items?.pumpingCompleted
                                              ? Index.moment(
                                                  items?.pumpingCompleted
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Cast Off:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {/* {items?.castOff} */}
                                            {items?.castOff
                                              ? Index.moment(
                                                  items?.castOff
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Supplied Quantity:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.suppliedQty
                                              ? items?.suppliedQty
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Issued Challan Number
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.issuedChallanNumber
                                              ? items?.issuedChallanNumber
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Issued Challan Date:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {/* {items?.issuedChallanDate} */}
                                            {items?.issuedChallanDate
                                              ? Index.moment(
                                                  items?.issuedChallanDate
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Anchor Dropped At Area Bunder:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {/* {items?.issuedChallanDate} */}
                                            {items?.anchorDroppedAtAreaBunder
                                              ? Index.moment(
                                                  items?.anchorDroppedAtAreaBunder
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          RCVD BDN:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.rcvdBdn
                                              ? Index.moment(
                                                  items?.rcvdBdn
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Lop Issue:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.lopIssue
                                              ? items?.lopIssue
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Lop Issue (Remark):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.remarkOfLop
                                              ? items?.remarkOfLop
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Dispute:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.dispute
                                              ? items?.dispute
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Dispute (Remark)
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.remarkofDispute
                                              ? items?.remarkofDispute
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Rough Weather:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.roughWeather
                                              ? items?.roughWeather
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Rough Weather Wind (Knots):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.wind ? items?.wind : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Rough Weather Swel (mtr):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.swel ? items?.swel : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Rough Weather Rain (Visibility):
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.rain ? items?.rain : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>

                                <Index.Grid
                                  container
                                  spacing={2}
                                  className="set-edit-timing-box"
                                >
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnType
                                              ? items?.returnType
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Cast Off:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnCastOff
                                              ? Index.moment(
                                                  items?.returnData
                                                    ?.returnCastOff
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Anchor Drop:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnAnchorDrop
                                              ? Index.moment(
                                                  items?.returnData
                                                    ?.returnAnchorDrop
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Diesel Consumption:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData
                                              ?.returnDieselConsumption
                                              ? items?.returnData
                                                  ?.returnDieselConsumption
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Reason:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnReason
                                              ? items?.returnData?.returnReason
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>

                                <Index.Grid
                                  container
                                  spacing={2}
                                  className="set-edit-timing-box"
                                >
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Waiting:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.waiting === true
                                              ? "Yes"
                                              : "No"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Drop Anchor At Anchorage:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.dropAnchorAtAnchorage
                                              ? Index.moment(
                                                  items?.dropAnchorAtAnchorage
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Anchor Awigh From Anchorage:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.anchorAwighFromAnchorage
                                              ? Index.moment(
                                                  items?.anchorAwighFromAnchorage
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </Index.Grid>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        );
                      })
                    ) : (
                      <Index.Table>
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list loading-purchase"
                            colSpan={6}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.Table>
                    )}
                    {/* </Index.Grid> */}
                    {/* {challanData?.length > 0 ? (
                      <>
                        <Index.Grid
                          container
                          spacing={2}
                          className="set-edit-timing-box"
                        >
                          {challanData?.length ? (
                            challanData &&
                            challanData?.map((items, index) => {
                              return (
                                <>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnType
                                              ? items?.returnType
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Cast Off:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnCastOff
                                              ? Index.moment(
                                                  items?.returnData
                                                    ?.returnCastOff
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Anchor Drop:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnAnchorDrop
                                              ? Index.moment(
                                                  items?.returnData
                                                    ?.returnAnchorDrop
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Diesel Consumption:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData
                                              ?.returnDieselConsumption
                                              ? items?.returnData
                                                  ?.returnDieselConsumption
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Reason:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.returnData?.returnReason
                                              ? items?.returnData?.returnReason
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              );
                            })
                          ) : (
                            <Index.Table>
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list loading-purchase"
                                  colSpan={6}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.Table>
                          )}
                        </Index.Grid>
                      </>
                    ) : (
                      ""
                    )}
                    {challanData?.length > 0 ? (
                      <>
                        <Index.Grid
                          container
                          spacing={2}
                          className="set-edit-timing-box"
                        >
                          {challanData?.length ? (
                            challanData &&
                            challanData?.map((items, index) => {
                              return (
                                <>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Waiting:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.waiting === true
                                              ? "Yes"
                                              : "No"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Drop Anchor At Anchorage:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.dropAnchorAtAnchorage
                                              ? Index.moment(
                                                  items?.dropAnchorAtAnchorage
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box className="invoice-detail-item">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Anchor Awigh From Anchorage:
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Typography
                                            variant="label"
                                            component="label"
                                            className="input-label"
                                          >
                                            {items?.anchorAwighFromAnchorage
                                              ? Index.moment(
                                                  items?.anchorAwighFromAnchorage
                                                ).format("DD/MM/YYYY HH:mm")
                                              : "-"}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              );
                            })
                          ) : (
                            <Index.Table>
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list loading-purchase"
                                  colSpan={6}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.Table>
                          )}
                        </Index.Grid>
                      </>
                    ) : (
                      ""
                    )} */}
                  </Index.Box>
                </SingleOrderTab>
                {/* surveyour supply timing -> 4 */}
                {viewData?.transportation === "PIPELINE" ? (
                  <>
                    {/* surveyour supply timing */}
                    <SingleOrderTab
                      value={value}
                      index={4}
                      className="barge-common-box tab-content-box"
                    >
                      <PipelineSurveyourSupplyTiming id={id} />
                    </SingleOrderTab>
                  </>
                ) : (
                  <>
                    {/* pipeline surveyour supply timing */}
                    <SingleOrderTab
                      value={value}
                      index={4}
                      className="barge-common-box tab-content-box"
                    >
                      <SurveyourSupplyTiming id={id} />
                    </SingleOrderTab>
                  </>
                )}

                <SingleOrderTab
                  value={value}
                  index={5}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    <Index.Table className="challan-table challan-table-set challan-table-font edit-req-table">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Created Date</Index.TableCell>
                          <Index.TableCell>Updated Date</Index.TableCell>
                          <Index.TableCell>Status</Index.TableCell>
                          <Index.TableCell>Approved / Rejected</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {challanRequestData?.length ? (
                          challanRequestData &&
                          challanRequestData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss a"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.updatedAt).format(
                                      "DD/MM/YYYY h:mm:ss a"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell>
                                  {items?.requestStatus
                                    ? items?.requestStatus
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Button
                                    variant="contained"
                                    onClick={() =>
                                      challanEditRequestStatusApproved(items)
                                    }
                                    className="challan-status"
                                    disabled={items?.checkRequest === true}
                                  >
                                    Approved
                                  </Index.Button>
                                  <Index.Button
                                    variant="contained"
                                    align="left"
                                    className="challan-status reject-btn-align"
                                    onClick={() =>
                                      challanEditRequestStatusReject(items)
                                    }
                                    disabled={items?.checkRequest === true}
                                  >
                                    Rejected
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={6}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleOrderTab>
                {viewData?.transportation !== "PIPELINE" ? (
                  <>
                    {" "}
                    <SingleOrderTab
                      value={value}
                      index={6}
                      className="barge-common-box tab-content-box"
                    >
                      <Index.Box
                        className="common-button blue-button save-btn res-button-width loading-box"
                        // sx={{
                        //   display: "flex",
                        //   justifyContent: "end",
                        //   alignItems: "center",
                        //   margin: "10px",
                        // }}
                      >
                        <Index.Box className="loaded-comp-qty-box">
                          Total Loaded Quantity :{" "}
                          {totalLoadedQuantity.toFixed(3)}
                        </Index.Box>
                        {removedTankerCount > 0 ? (
                          <>
                            <Index.Button
                              variant="outlined"
                              onClick={() => {
                                window.open(
                                  `/admin/order/view-order/shift-tanker-history/${id}`
                                );
                              }}
                              className="download-all-tt-btn"
                            >
                              Show Shift Tanker History
                            </Index.Button>
                          </>
                        ) : (
                          <></>
                        )}

                        <Index.Button
                          variant="outlined"
                          onClick={handleDownload}
                          className="download-all-tt-btn"
                        >
                          Download All TT Documents{" "}
                          {documentLoading ? (
                            <Index.CircularProgress
                              color="secondary"
                              sx={{ marginLeft: "10px" }}
                              size={20}
                            />
                          ) : (
                            ""
                          )}
                        </Index.Button>
                        <Index.Box className="common-button blue-button save-btn res-button-width res-button-add-set shift-tanker-btn">
                          {(viewData?.orderStatus === "Cancel" ||
                            viewData?.orderStatus === "" ||
                            (viewData?.orderStatus === "Confirm" &&
                              loadingTankers?.length)) &&
                          tankerCheckId?.length ? (
                            <Index.Button
                              className="set-active-btn"
                              variant="contained"
                              type="submit"
                              onClick={shiftTankerHandleOpen}
                            >
                              Shift Tankers
                            </Index.Button>
                          ) : (
                            <></>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.TableContainer>
                        <Index.Table className="challan-table challan-table-set challan-table-font loading-table ">
                          <Index.TableHead>
                            <Index.TableRow>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell>Sr. No</Index.TableCell>
                              <Index.TableCell>Tanker Number</Index.TableCell>
                              <Index.TableCell>Capacity</Index.TableCell>
                              <Index.TableCell>Loaded Quantity</Index.TableCell>
                              <Index.TableCell>
                                Transferred Vessel
                              </Index.TableCell>
                              <Index.TableCell>Transferred By</Index.TableCell>
                              <Index.TableCell>
                                Transferred Time
                              </Index.TableCell>
                              <Index.TableCell>Action</Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody>
                            {loadingTankers?.length ? (
                              loadingTankers &&
                              loadingTankers?.map((items, index) => {
                                return (
                                  <>
                                    <Index.TableRow>
                                      <Index.TableCell>
                                        {viewData?.orderStatus === "Cancel" ||
                                        viewData?.orderStatus === "Confirm" ||
                                        viewData?.orderStatus === "" ? (
                                          <>
                                            <Index.FormControlLabel
                                              control={
                                                <Index.Checkbox
                                                  key={items?.tanker?._id}
                                                  onChange={(event) => {
                                                    if (
                                                      event.target.checked ===
                                                      true
                                                    ) {
                                                      setTankerCheckId([
                                                        ...tankerCheckId,
                                                        items?.tanker?._id,
                                                      ]);
                                                    } else {
                                                      setTankerCheckId(
                                                        tankerCheckId?.filter(
                                                          (ele) => {
                                                            return (
                                                              ele !==
                                                              items?.tanker?._id
                                                            );
                                                          }
                                                        )
                                                      );
                                                    }
                                                  }}
                                                />
                                              }
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        <Index.Typography>
                                          {index + 1}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell>
                                        <Index.Typography>
                                          {items?.tanker?.tankerNumber
                                            ? items?.tanker?.tankerNumber
                                            : "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell>
                                        <Index.Typography>
                                          {items?.tanker?.capacity
                                            ? items?.tanker?.capacity
                                            : "-"}
                                        </Index.Typography>
                                      </Index.TableCell>

                                      <Index.TableCell>
                                        <Index.Typography>
                                          {items?.loadedQty
                                            ? items?.loadedQty
                                            : "-"}
                                        </Index.Typography>
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {items?.hasOwnProperty("shiftTanker")
                                          ? items?.shiftTanker?.hasOwnProperty(
                                              "oldId"
                                            )
                                            ? items?.shiftTanker?.oldId
                                                ?.vesselName
                                            : "-"
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {items?.hasOwnProperty("shiftTanker")
                                          ? items?.shiftTanker?.hasOwnProperty(
                                              "transferBy"
                                            )
                                            ? items?.shiftTanker?.transferBy?.hasOwnProperty(
                                                "firstName"
                                              ) &&
                                              items?.shiftTanker?.transferBy?.hasOwnProperty(
                                                "lastName"
                                              )
                                              ? items?.shiftTanker?.transferBy
                                                  ?.firstName +
                                                " " +
                                                items?.shiftTanker?.transferBy
                                                  ?.lastName
                                              : "-"
                                            : "-"
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {items?.hasOwnProperty("shiftTanker")
                                          ? items?.shiftTanker?.hasOwnProperty(
                                              "createdAt"
                                            )
                                            ? Index.moment(
                                                items?.shiftTanker?.createdAt
                                              ).format("DD/MM/YYYY hh:mm:ss A")
                                            : "-"
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell>
                                        {showModal ? (
                                          items?._id === openModalId ? (
                                            <Index.IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              <Index.KeyboardArrowUpIcon
                                                onClick={() =>
                                                  showTankerDetails(
                                                    items,
                                                    "down"
                                                  )
                                                }
                                              />
                                            </Index.IconButton>
                                          ) : (
                                            <Index.IconButton
                                              aria-label="expand row"
                                              size="small"
                                            >
                                              <Index.KeyboardArrowDownIcon
                                                onClick={() =>
                                                  showTankerDetails(items, "up")
                                                }
                                              />
                                            </Index.IconButton>
                                          )
                                        ) : (
                                          <Index.IconButton
                                            aria-label="expand row"
                                            size="small"
                                          >
                                            <Index.KeyboardArrowDownIcon
                                              onClick={() =>
                                                showTankerDetails(items, "up")
                                              }
                                            />
                                          </Index.IconButton>
                                        )}
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleTankerDashboardView(
                                                items?._id,
                                                "loadingTankerDashboard"
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        {items?.document?.length ? (
                                          <>
                                            <Index.IconButton>
                                              <Index.CloudDownloadIcon
                                                variant="contained"
                                                disableRipple
                                                onClick={() => {
                                                  handleDownloadImage(
                                                    items,
                                                    items?.tanker?.tankerNumber
                                                  );
                                                }}
                                              />
                                            </Index.IconButton>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                    {showModal &&
                                      tankerLoadingDetail &&
                                      items?._id === openModalId && (
                                        <Index.TableRow>
                                          <Index.TableCell
                                            colSpan={6}
                                            className="table-view-data"
                                          >
                                            <Index.Collapse
                                              in={showModal}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content mb-10">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Operation Supervisor
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loading TT In
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadedTTIn &&
                                                        tankerLoadingDetail?.loadedTTIn !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.loadedTTIn
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loading Start
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadingStart &&
                                                        tankerLoadingDetail?.loadingStart !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.loadingStart
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loading Completed
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadingCompleted &&
                                                        tankerLoadingDetail?.loadingCompleted !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.loadingCompleted
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loaded Quantity
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadedQty
                                                          ? tankerLoadingDetail?.loadedQty
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loaded TT Out
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadedTTOut &&
                                                        tankerLoadingDetail?.loadedTTOut !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.loadedTTOut
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Loading Remark
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.loadingRemark
                                                          ? tankerLoadingDetail?.loadingRemark
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>

                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content mb-10">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Kandla Supervisor
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Kandla IN
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.kandalaIn &&
                                                        tankerLoadingDetail?.kandalaIn !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.kandalaIn
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Kandla Out
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.kandalaOut &&
                                                        tankerLoadingDetail?.kandalaOut !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.kandalaOut
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Kandla Remark
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.kandalaRemark
                                                          ? tankerLoadingDetail?.kandalaRemark
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>

                                              <Index.Box className="set-edit-timing-box pd-loading-details">
                                                <Index.Grid
                                                  container
                                                  spacing={2}
                                                >
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                  >
                                                    <Index.Box className="title-header-flex order-loading-details">
                                                      <Index.Box className="title-main order-loading-content mb-10">
                                                        <Index.Typography
                                                          variant="p"
                                                          component="p"
                                                          className="page-title order-loading-title"
                                                        >
                                                          Surveyor
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Unloading Start
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.unloadingStart &&
                                                        tankerLoadingDetail?.unloadingStart !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.unloadingStart
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Barge Selection
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail
                                                          ?.bargeSelection
                                                          ?.bargeName
                                                          ? tankerLoadingDetail
                                                              ?.bargeSelection
                                                              ?.bargeName
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Unloading Completed
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.unloadingCompleted &&
                                                        tankerLoadingDetail?.unloadingCompleted !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.unloadingCompleted
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Sounding Calculation
                                                          Barge
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.soundingCalculation
                                                          ? tankerLoadingDetail?.soundingCalculation
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={3}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Barge Loading
                                                          Completed
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.dignityLoadingCompleted &&
                                                        tankerLoadingDetail?.dignityLoadingCompleted !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.dignityLoadingCompleted
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  {/*loading rcvd bdn  */}
                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          RCVD BDN
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.rcvdBdn &&
                                                        tankerLoadingDetail?.rcvdBdn !==
                                                          "Invalid date"
                                                          ? Index.moment(
                                                              tankerLoadingDetail?.rcvdBdn
                                                            ).format(
                                                              "DD/MM/YYYY HH:mm"
                                                            )
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={2}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          Surveyor Remark
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {tankerLoadingDetail?.surveyorRemark
                                                          ? tankerLoadingDetail?.surveyorRemark
                                                          : "-"}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>

                                                  <Index.Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={5}
                                                  >
                                                    <Index.Box>
                                                      <Index.Box>
                                                        <Index.Typography
                                                          variant="label"
                                                          component="label"
                                                          className="input-label opacity-60"
                                                        >
                                                          TT Invoice
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    </Index.Box>

                                                    <Index.Box className="input-design-div with-border">
                                                      <Index.Typography
                                                        variant="label"
                                                        component="label"
                                                        className="input-label"
                                                      >
                                                        {items?.document &&
                                                          items?.document.map(
                                                            (item, ind) => (
                                                              <Index.Button
                                                                onClick={() => {
                                                                  window.open(
                                                                    `${process.env.REACT_APP_IMAGE_URL}/loadingDoc/${item}`
                                                                  );
                                                                }}
                                                              >
                                                                {item}
                                                              </Index.Button>
                                                            )
                                                          )}
                                                      </Index.Typography>
                                                    </Index.Box>
                                                  </Index.Grid>
                                                </Index.Grid>
                                              </Index.Box>
                                            </Index.Collapse>
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      )}
                                  </>
                                );
                              })
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list"
                                  colSpan={6}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </SingleOrderTab>
                  </>
                ) : (
                  <>
                    {" "}
                    <SingleOrderTab
                      value={value}
                      index={6}
                      className="barge-common-box tab-content-box"
                    >
                      <PipeLineDetailsPage orderId={id} />
                    </SingleOrderTab>
                  </>
                )}

                <SingleOrderTab
                  value={value}
                  index={7}
                  className="barge-common-box tab-content-box"
                >
                  <OrderTransfer params={id} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={8}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    <Index.Table className="invoice-activity-log invoice-activity-log-set order-activity-log">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Activity Type</Index.TableCell>
                          <Index.TableCell>Details</Index.TableCell>
                          <Index.TableCell>Date & Time</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {filterOrderLogData &&
                          filterOrderLogData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.activityType}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.details}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleOrderTab>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      )}
      <OrderStatusModel
        updateOpen={cancelOpen}
        handleClose={cancelOrderModelClose}
        inquiryStatusUpdate={cancelOrderBtn}
        modalType={status ? "Comment" : ""}
      />
      <TankerShiftModel
        shiftTankerHandleOpen={shiftTankerHandleOpen}
        shiftTankerHandleClose={shiftTankerHandleClose}
        tankerOpen={tankerOpen}
        setTankerOpen={setTankerOpen}
        orderId={id}
        status={viewData?.orderStatus}
        shiftTankerStatus={viewData?.tankerShifted}
        getSingleOrderList={getSingleOrderList}
        setIsShifted={setIsShifted}
        tankerCheckId={tankerCheckId}
        setTankerCheckId={setTankerCheckId}
      />
    </>
  );
}

export default SingleOrder;
