import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addOrderDocument,
  deleteOrderDocument,
  getSingleOrder,
} from "../../../../redux/admin/action";
import { orderDocumentValidationSchema } from "../../../../validation/AdminValidation";
import { toast } from "react-toastify";
import JSZip from "jszip";

const OrderDocument = ({ id, viewData, setViewData }) => {
  const dispatch = Index.useDispatch();
  const [fieldName, setFieldName] = useState("");
  const [fileName, setFileName] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);

  let initialValues = {
    bunkerPermissionOption: [],
    jettyPermission: [],
    firePermission: [],
    underTaking: [],
    shippingBillDoc: [],
    shortShipment: [],
    pc: [],
    ullageReport: [],
    bdn: [],
    lop: [],
  };

  const handleFormSubmit = (values, { setFieldValue }) => {
    setLoading(true);
    const data = new FormData();
    data.append("id", id);
    values.bunkerPermissionOption.forEach((ele, i) => {
      data.append("bunkerPermissionOption", ele);
    });

    values.jettyPermission.forEach((ele, i) => {
      data.append("jettyPermission", ele);
    });

    values.firePermission.forEach((ele, i) => {
      data.append("firePermission", ele);
    });

    values.underTaking.forEach((ele, i) => {
      data.append("underTaking", ele);
    });

    values.shippingBillDoc.forEach((ele, i) => {
      data.append("shippingBillDoc", ele);
    });

    values.shortShipment.forEach((ele, i) => {
      data.append("shortShipment", ele);
    });

    values.pc.forEach((ele, i) => {
      data.append("pc", ele);
    });

    values.ullageReport.forEach((ele, i) => {
      data.append("ullageReport", ele);
    });

    values.bdn.forEach((ele, i) => {
      data.append("bdn", ele);
    });

    values.lop.forEach((ele, i) => {
      data.append("lop", ele);
    });
    dispatch(addOrderDocument(data)).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        setFieldValue("bunkerPermissionOption", []);
        setFieldValue("jettyPermission", []);
        setFieldValue("firePermission", []);
        setFieldValue("underTaking", []);
        setFieldValue("shippingBillDoc", []);
        setFieldValue("shortShipment", []);
        setFieldValue("pc", []);
        setFieldValue("ullageReport", []);
        setFieldValue("bdn", []);
        setFieldValue("lop", []);
        dispatch(getSingleOrder({ id })).then((res) => {
          if (res) {
            setViewData(res?.data);
          }
        });
      } else {
        setLoading(false);
      }
    });
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("bunkerPermissionOption", fieldName);
    data.append("file", fileName);

    dispatch(deleteOrderDocument(data, handleDeleteClose)).then((res) => {
      if (res?.status === 200) {
        dispatch(getSingleOrder({ id })).then((res) => {
          if (res?.status === 200) {
            setViewData(res?.data);
          }
        });
      }
    });
    handleDeleteClose();
  };

  // when the user want to download the images then used...
  const handleDownloadImage = (imageUrl, imageName) => {
    if (imageUrl !== null && imageUrl !== undefined) {
      // setDocumentLoading(true)
      fetch(imageUrl, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", imageName); //or any other extension
            document.body.appendChild(link);
            link.click();
            // setDocumentLoading(false)
          });
        })
        .catch((err) => {
          // console.log("Image Download time error", err);
          toast.error("Document Doesn't Exist");
        });
    } else {
      toast.error("Document Doesn't Exist");
    }
  };

  // when the user download all the images then used...
  const handleDownload = () => {
    if (viewData !== null && viewData !== undefined) {
      setDocumentLoading(true);
      let imageURLs = [];
      const {
        bunkerPermissionOption,
        jettyPermission,
        firePermission,
        underTaking,
        shippingBillDoc,
        shortShipment,
        pc,
        ullageReport,
        bdn,
        lop,
      } = viewData;
      if (bunkerPermissionOption?.length) {
        bunkerPermissionOption?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${item}`,
            fileName: `Bunker_Permission_Option_${item}`,
          });
        });
      }
      if (jettyPermission?.length) {
        jettyPermission?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${item}`,
            fileName: `Jetty_Permission_${item}`,
          });
        });
      }
      if (firePermission?.length) {
        firePermission?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${item}`,
            fileName: `Fire_Permission_${item}`,
          });
        });
      }
      if (underTaking?.length) {
        underTaking?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${item}`,
            fileName: `Undertaking_${item}`,
          });
        });
      }
      // ------------------------------
      if (shippingBillDoc?.length) {
        shippingBillDoc?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${item}`,
            fileName: `Shipping_Bill_${item}`,
          });
        });
      }
      if (shortShipment?.length) {
        shortShipment?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${item}`,
            fileName: `Short_Shipment_${item}`,
          });
        });
      }
      if (pc?.length) {
        pc?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${item}`,
            fileName: `PC_${item}`,
          });
        });
      }
      // -----------------
      if (ullageReport?.length) {
        ullageReport?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${item}`,
            fileName: `Ullage_Report_${item}`,
          });
        });
      }
      if (bdn?.length) {
        bdn?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${item}`,
            fileName: `BDN_${item}`,
          });
        });
      }
      if (lop?.length) {
        lop?.forEach((item) => {
          imageURLs.push({
            path: `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${item}`,
            fileName: `LOP_${item}`,
          });
        });
      }

      if (imageURLs?.length) {
        const zip = new JSZip();

        // Fetch each image and add it to the zip file
        const addImageToZip = async (url, index) => {
          const response = await fetch(url?.path);
          const arrayBuffer = await response.arrayBuffer();
          const extension = url?.path.substring(
            url?.path.lastIndexOf(".") + 1,
            url?.path.length
          );
          zip.file(`${url?.fileName}_${index + 1}.${extension}`, arrayBuffer);
        };

        // Add all images to the zip file
        Promise.all(imageURLs.map(addImageToZip))
          .then(() => {
            // Generate the zip file and initiate the download
            zip.generateAsync({ type: "blob" }).then((content) => {
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.download = "All-Documents.zip";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              setDocumentLoading(false);
            });
          })
          .catch((error) => {
            console.error("Error adding documents to zip:", error);
            setDocumentLoading(false);
          });
      } else {
        toast.error("Document Doesn't Exist");
        setDocumentLoading(false);
      }
    } else {
      toast.error("Document Doesn't Exist");
      setDocumentLoading(false);
    }
  };

  return (
    <>
      <>
        <Index.Box className="">
          <Index.Box className="barge-common-box document-wrapper br-none">
            <Formik
              // enableReinitialize
              initialValues={initialValues}
              validationSchema={orderDocumentValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="">
                    <Index.Grid container spacing={2}>
                      <Index.Box className="title-header-flex">
                        <Index.Box
                          className="title-main"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Permission Documents
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box
                          className="common-button blue-button save-btn res-button-width"
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            margin: "10px",
                          }}
                        >
                          <Index.Button
                            variant="outlined"
                            onClick={handleDownload}
                          >
                            Download All Documents
                            {documentLoading ? (
                              <Index.CircularProgress
                                color="secondary"
                                sx={{ marginLeft: "10px" }}
                                size={20}
                              />
                            ) : (
                              ""
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                      <Index.Grid item xs={12} sm={6} md={3}>

                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Bunker Permission Option:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                          <Index.Grid item xs={6} sm={6} md={12}>
                            <Index.Box className="input-design-div with-border position-relative">
                              <Index.Box className="pdf-design">
                                Upload File
                              </Index.Box>
                              <Index.TextField
                                fullWidth
                                type="file"
                                name="bunkerPermissionOption"
                                multiple
                                id="filled-hidden-label-normal"
                                variant="filled"
                                onBlur={handleBlur}
                                className="admin-input-design input-placeholder pdf-upload-design"
                                onChange={(event) => {
                                  const files = Array.from(event.target.files);
                                  setFieldValue("bunkerPermissionOption", [
                                    ...files,
                                  ]);
                                }}
                                inputProps={{
                                  multiple: true,
                                }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                              />
                              {errors.bunkerPermissionOption && (
                                <Index.FormHelperText error>
                                  {errors.bunkerPermissionOption}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                            <Index.Grid item xs={6} sm={6} md={12} sx={{}}>
                              {values?.bunkerPermissionOption?.map(
                                (items, index) => {
                                  return <li>{items?.name}</li>;
                                }
                              )}
                            </Index.Grid>
                            <Index.Grid item xs={6} sm={6} md={12}>
                              {viewData?.bunkerPermissionOption?.map(
                                (items, index) => {
                                  return (
                                    <>
                                      <Index.Box sx={{ display: "flex" }}>
                                        <>
                                          {
                                            <>
                                              <Index.IconButton>
                                                <Index.VisibilitySharpIcon
                                                  onClick={() => {
                                                    window.open(
                                                      `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`
                                                    );
                                                  }}
                                                />
                                              </Index.IconButton>
                                              <Index.IconButton>
                                                <Index.CloudDownloadIcon
                                                  variant="contained"
                                                  disableRipple
                                                  onClick={() => {
                                                    handleDownloadImage(
                                                      `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`,
                                                      `Bunker_Permission_Option_${items}`
                                                    );
                                                  }}
                                                />
                                              </Index.IconButton>
                                            </>
                                          }
                                          <Index.IconButton>
                                            <Index.DeleteIcon
                                              color="error"
                                              onClick={() => {
                                                setFieldName(
                                                  "bunkerPermissionOption"
                                                );
                                                setFileName(items);
                                                handleDeleteOpen();
                                              }}
                                            />
                                          </Index.IconButton>
                                        </>
                                      </Index.Box>
                                    </>
                                  );
                                }
                              )}
                            </Index.Grid>
                          </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Jetty Permission:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="jettyPermission"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("jettyPermission", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.jettyPermission && (
                              <Index.FormHelperText error>
                                {errors.jettyPermission}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.jettyPermission?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.jettyPermission?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`,
                                                `Jetty_Permission_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("jettyPermission");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Fire Permission:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="firePermission"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("firePermission", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.firePermission && (
                              <Index.FormHelperText error>
                                {errors.firePermission}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.firePermission?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.firePermission?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`,
                                                `Fire_Permission_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("firePermission");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Under Taking:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="underTaking"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("underTaking", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.underTaking && (
                              <Index.FormHelperText error>
                                {errors.underTaking}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.underTaking?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.underTaking?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/permission/${items}`,
                                                `Under_Taking_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("underTaking");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>

                  <Index.Box className="">
                    <Index.Grid container spacing={2}>
                      <Index.Box className="title-header-flex">
                        <Index.Box className="title-main mb-10">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title order-document-heading"
                          >
                            Customs/Port Documents
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Shipping Bill:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="shippingBillDoc"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("shippingBillDoc", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.shippingBillDoc && (
                              <Index.FormHelperText error>
                                {errors.shippingBillDoc}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.shippingBillDoc?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.shippingBillDoc?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`,
                                                `Shipping_Bill_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("shippingBillDoc");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Short Shipment:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="shortShipment"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("shortShipment", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.shortShipment && (
                              <Index.FormHelperText error>
                                {errors.shortShipment}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.shortShipment?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.shortShipment?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`,
                                                `Short_Shipment_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("shortShipment");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          PC:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="pc"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("pc", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.pc && (
                              <Index.FormHelperText error>
                                {errors.pc}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.pc?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.pc?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/custom/${items}`,
                                                `PC_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("pc");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>

                  <Index.Box className="">
                    <Index.Grid container spacing={2}>
                      <Index.Box className="title-header-flex">
                        <Index.Box className="title-main mb-10">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title order-document-heading"
                          >
                            Supply Documents
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Ullage Report:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="ullageReport"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("ullageReport", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.ullageReport && (
                              <Index.FormHelperText error>
                                {errors.ullageReport}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.ullageReport?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.ullageReport?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`,
                                                `Ullage_Report_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("ullageReport");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          BDN:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="bdn"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("bdn", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.bdn && (
                              <Index.FormHelperText error>
                                {errors.bdn}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.bdn?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.bdn?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`,
                                                `BDN_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("bdn");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          LOP:
                        </Index.Typography>
                        <Index.Box className="upload-wrapper">
                        <Index.Grid item xs={6} sm={6} md={12}>
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="pdf-design">
                              Upload File
                            </Index.Box>
                            <Index.TextField
                              fullWidth
                              type="file"
                              name="lop"
                              multiple
                              id="filled-hidden-label-normal"
                              variant="filled"
                              className="admin-input-design input-placeholder pdf-upload-design"
                              onChange={(event) => {
                                const files = Array.from(event.target.files);
                                setFieldValue("lop", [...files]);
                              }}
                              inputProps={{
                                multiple: true,
                              }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                            />
                            {errors.lop && (
                              <Index.FormHelperText error>
                                {errors.lop}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Grid item xs={6} sm={6} md={12}>
                            {values?.lop?.map((items, index) => {
                              return <li>{items?.name}</li>;
                            })}
                          </Index.Grid>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            {viewData?.lop?.map((items, index) => {
                              return (
                                <>
                                  <Index.Box sx={{ display: "flex" }}>
                                    {
                                      <>
                                        <Index.IconButton>
                                          <Index.VisibilitySharpIcon
                                            onClick={() => {
                                              window.open(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                        <Index.IconButton>
                                          <Index.CloudDownloadIcon
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleDownloadImage(
                                                `${process.env.REACT_APP_IMAGE_URL}/orders/supply/${items}`,
                                                `LOP_${items}`
                                              );
                                            }}
                                          />
                                        </Index.IconButton>
                                      </>
                                    }
                                    <Index.IconButton>
                                      <Index.DeleteIcon
                                        color="error"
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setFieldName("lop");
                                          setFileName(items);
                                          handleDeleteOpen();
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.Box>
                                </>
                              );
                            })}
                          </Index.Grid>
                        </Index.Grid>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>

                  <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                    <Index.Button variant="contained" type="submit">
                      {loading ? (
                        <Index.CircularProgress color="secondary" size={20} />
                      ) : (
                        "Submit"
                      )}
                    </Index.Button>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      </>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        orderDocument={"orderDocument"}
      />
    </>
  );
};

export default OrderDocument;
