import Index from "../../../Index";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function BetaPurchaseDetailModal({
  openPurchaseDetailModal,
  handleClosePurchaseDetailModal,
  invoiceData,
  invoiceLoading,
}) {
  return (
    <>
      <Index.Modal
        open={openPurchaseDetailModal}
        onClose={handleClosePurchaseDetailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-invoice-main admin-modal"
      >
        <Index.Box
          sx={style}
          className="expense-model-main  modal-invoice-detail admin-modal-inner"
        >
              <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              Invoice Details
            </Index.Typography>
            
                 <Index.Button className="modal-close-btn" onClick={handleClosePurchaseDetailModal}>
                 <Index.ClearIcon
                  className="model-close-icon"
                />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-body">
            <Index.Box className="admin-modal-hgt-scroll">

            <Index.Box className="page-table-main">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main invoice-table invoice-table-set table"
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell className="invoice_table_header">
                        Amount
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Amount Forex
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Ledger
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Company Name
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>

                  {invoiceLoading ? (
                    <Index.TableBody>
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={15}
                          align="center"
                          className="loading-purchase"
                        >
                          <Index.CircularProgress color="secondary" size={20} />
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  ) : (
                    <Index.TableBody>
                      {invoiceData?.length ? (
                        invoiceData?.map((row, index) => (
                          <Index.TableRow key={row?.guid + (index + 1)}>
                            <Index.TableCell className="invoice-view-cursor">
                              {row?.amount ? row?.amount : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.amount_forex ? row?.amount_forex : "-"}
                            </Index.TableCell>{" "}
                            <Index.TableCell>
                              {row?.ledger ? row?.ledger : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.company_name ? row?.company_name : "-"}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list empty-record-align"
                            colSpan={19}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  )}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
          </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
export default BetaPurchaseDetailModal;
